<template>
  <div class="send-record-manage"><!--v-loading="pageLoading"-->
    <div class="send-record-manage-title">
      <div class="text">
        <span>推送记录</span>
        <div class="line"></div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form style="margin-top: 2.65625vw" :model="searchForm" onsubmit="return false" :inline="true">
      <el-row>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-input class="form-input" v-model="searchForm.catalogueName" placeholder="目录标题" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-input class="form-input" v-model="searchForm.sendPerson" placeholder="推送人" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.courseName" clearable placeholder="关联课程">
              <el-option v-for="item in courseOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-select class="form-input" v-model="searchForm.status" clearable placeholder="推送状态">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item class="form-search-item">
            <el-date-picker style="width: 100%" v-model="dataTimes" type="daterange" range-separator="至" start-placeholder="推送开始日期" end-placeholder="推送结束日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-refresh-right" @click="resetFun">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="table-content">
      <l-table :columns="columns" :dataSource="tableData" :pagination="pageFlag" :ipagination="page" :showBorder="true" @current-change="handleCurrentChange" @size-change="handleSizeChange" :showXh="true" :height="'calc(100% - 2.91667vw)'" :autoHeight="false"><!--:loading="tableLoading"-->
        <el-table-column prop="catalogueName" label="推送标题" align="center" min-width="150" slot="catalogueName">
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.catalogueName }}</div>
              <div class="handleData">{{ scope.row.catalogueName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="关联课程" align="center" slot="courseName" min-width="150">
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.courseName }}</div>
              <div class="handleData">{{ scope.row.courseName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="courseThemeName" label="关联主题/活动" align="center" slot="courseThemeName" min-width="150">
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.courseThemeName }}</div>
              <div class="handleData">{{ scope.row.courseThemeName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="publishCount" label="推送数量" align="center" slot="publishCount" min-width="80">
          <template slot-scope="scope">
            <span class="table-edit" @click="handleShowStudent(scope.row)">{{ scope.row.publishCount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="submitInfo" slot="submitInfo" label="已查看/已提交" min-width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.submitType != '' && scope.row.status === '1' ? ((scope.row.lookCount === null ? 0 : scope.row.lookCount) + " / " + (scope.row.submitCount === null ? 0 : scope.row.submitCount)) : (scope.row.submitType == '' && scope.row.status === '1' ? ((scope.row.lookCount === null ? 0 : scope.row.lookCount) + " / " + '无需提交') : '') }}</span><!-- + " / " + (scope.row.noSubmitCount === null ? 0 : scope.row.noSubmitCount) -->
          </template>
        </el-table-column>
        <el-table-column prop="likeCount" label="点赞" align="center" slot="likeCount" min-width="80"></el-table-column>
        <el-table-column prop="replyCount" slot="replyCount" label="回复" align="center" min-width="80"></el-table-column>
        <el-table-column prop="sendPerson" slot="sendPerson" label="推送人" min-width="120" align="center"></el-table-column>
        <el-table-column prop="updateTime" slot="updateTime" label="推送时间" min-width="140" align="center">
           <template slot-scope="scope">
            <span>{{ scope.row.status === '0' ? '' : scope.row.updateTime.substr(0,16) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" slot="status" min-width="120" label="推送状态" align="center">
          <template slot-scope="scope">
            <div style="color: #FAAD14;border: undefined solid #FFAD4D;background-color: #FFF6EB;" class="status-show" v-if="scope.row.status === '0'">未推送</div>
            <div style="color: #597EF7;border: undefined solid #597EF7;background-color: #E6F7FF;" class="status-show" v-if="scope.row.status === '1'">已推送</div>
            <div style="color: #A0A5B9;border: undefined solid #D8D8D8;background-color: #F5F5F5;" class="status-show" v-if="scope.row.status === '2'">已撤销</div>
          </template>
        </el-table-column>
        <el-table-column prop="submitStatus" slot="submitStatus" min-width="120" label="提交状态" align="center">
          <template slot-scope="scope">
            <div style="color: #FAAD14;border: undefined solid #FFAD4D;background-color: #FFF6EB;" class="status-show" v-if="scope.row.submitStatus === '0'">未过期</div>
            <div style="color: #A0A5B9;border: undefined solid #D8D8D8;background-color: #F5F5F5;" class="status-show" v-if="scope.row.submitStatus === '1'">已过期</div>
            <div style="color: #597EF7;border: undefined solid #597EF7;background-color: #E6F7FF;" class="status-show" v-if="scope.row.submitStatus === '2'">永久</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="handle" fixed="right" align="center" width="220">
          <template slot-scope="scope">
            <span class="table-edit" @click="handleView(scope.row)">预览</span>
            <span class="table-more-edit" v-if="isView && scope.row.submitType != ''" @click="handleDetail(scope.row)">查看评论</span>
            <span class="table-more-edit" @click="handleEdit(scope.row)" v-if="scope.row.status !== '1' && isEdit">编辑并推送</span>
            <span class="table-del" @click="handleDel(scope.row)" v-if="scope.row.status === '1' && isRevoke">撤销</span>
          </template>
        </el-table-column>
      </l-table>
    </div>
    <el-dialog :title="sendDialogTitle" :visible.sync="sendDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form class="dialog-form" :model="sendDialogForm" ref="sendDialogForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="所属组织：" prop="organization">
              <el-select class="form-input" v-model="sendDialogForm.organization" clearable placeholder="请选择组织">
                <el-option v-for="item in organizationOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="所属学校：" prop="schoolName">
              <el-select class="form-input" v-model="sendDialogForm.schoolName" clearable placeholder="请选择学校">
                <el-option v-for="item in schoolOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="年级名称：" prop="gradeName">
              <el-input class="form-input" v-model="sendDialogForm.gradeName" placeholder="请输入年级名称，支持汉字、数字、字母组合" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="年级编号：" prop="gradeNum">
              <el-input class="form-input" v-model="sendDialogForm.gradeNum" placeholder="请输入年级编号" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="排序：" prop="sort">
              <el-input class="form-input" v-model="sendDialogForm.sort" placeholder="请输入排序" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="状态：" prop="status">
              <el-switch v-model="sendDialogForm.status"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="备注：">
              <el-input class="form-input" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请填写描述内容..." v-model="sendDialogForm.description" resize="none" :maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="推送学生列表" :visible.sync="publishStudentListDialogVisible" @opened="handleOpenDialog" :append-to-body="true" :destroy-on-close="true" @before-close="handlePublishStudentListClose" @close="handlePublishStudentListClose" width="50%">
      <div class="student-record-list">
        <l-table :columns="publishStudentColumns" :showBorder="true" :dataSource="publishStudentTableData" :pagination="publishStudentPageFlag" :ipagination="publishStudentPage" @current-change="handlePublishStudentCurrentChange" @size-change="handlePublishStudentSizeChange" :height="null" :showXh="true" :autoHeight="false"><!--:loading="tableLoading"-->
          <el-table-column prop="className" label="班级" align="center" min-width="150" slot="className"></el-table-column>
          <el-table-column prop="studentName" label="姓名" align="center" slot="studentName" min-width="150"></el-table-column>
        </l-table>
      </div>
    </el-dialog>
    <publish-view :courseViewDialogVisible.sync="courseViewDialogVisible" :contentId="courseContentCatalogId"></publish-view>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import LTable from '@/components/tableComponent/index.vue';
import PublishView from '@/components/publishView/index.vue';
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: 'sendRecordManage',
  components: {
    LTable,
    PublishView
  },
  data() {
    return {
      pageLoading: null, // 页面loading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false, // 是否显示分页
      tableData: [], // 表格数据
      tableLoading: false, // 表格loading
      selectData: [], // 选中数据
      columns: [
        {
          label: '推送标题',
          slotName: 'catalogueName',
        },
        {
          label: '关联课程',
          slotName: 'courseName',
        },
        {
          label: '关联主题/活动',
          slotName: 'courseThemeName',
        },
        {
          label: '推送数量',
          slotName: 'publishCount',
        },
        {
          label: '已查看/已提交',
          slotName: 'submitInfo',
        },
        {
          label: '点赞',
          slotName: 'likeCount',
        },
        {
          label: '回复',
          slotName: 'replyCount',
        },
        {
          label: '推送人',
          slotName: 'sendPerson',
        },
        {
          label: '推送时间',
          slotName: 'updateTime',
        },
        {
          label: '推送状态',
          slotName: 'status',
        },
        {
          label: '提交状态',
          slotName: 'submitStatus',
        },
        {
          label: '操作',
          slotName: 'handle',
        },
      ],
      searchForm: {
        catalogueName: '',
        sendPerson: '',
        courseName: '',
        startTime: '',
        endTime: '',
        status: '',
      }, // 搜索条件
      dataTimes: [], // 时间范围
      organizationOptions: [], // 组织下拉
      statusOptions: [{ name: '未推送', value: '0' }, { name: '已推送', value: '1' }], // 状态下拉
      schoolOptions: [], // 学校下拉
      courseOptions: [], // 课程下拉

      sendDialogTitle: '',
      sendDialogVisible: false, // 编辑推送的弹窗显隐标识
      sendDialogForm: {
        organization: '',
        schoolName: '',
        gradeName: '',
        gradeNum: '',
        sort: '',
        status: true,
        description: '',
      }, // 批量分班弹窗form
      rules: {
        organization: [
          { required: true, message: '请选择所属组织', trigger: 'change' },
        ],
        schoolName: [
          { required: true, message: '请选择所属学校', trigger: 'change' },
        ],
        gradeName: [
          { required: true, message: '请输入年级名称', trigger: 'blur' },
        ],
        gradeNum: [
          { required: true, message: '请输入年级编号', trigger: 'blur' },
        ],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
      }, // 表单规则
      buttonloading: false, // 按钮loading

      publishStudentListDialogVisible: false, //推送学生列表弹窗标识
      publishStudentColumns: [
        {
          label: '班级',
          slotName: 'className',
        },
        {
          label: '姓名',
          slotName: 'studentName',
        }
      ],
      publishStudentTableData: [], // 推送学生列表表格数据
      publishStudentPageFlag: false, // 推送学生列表是否显示分页
      publishStudentPage: {
        num: 1,
        size: 10,
        total: 0,
      }, // 推送学生列表分页信息
      publishId: null, //推送记录id

      courseViewDialogVisible: false, // 预览
      courseContentCatalogId: null, // 预览目录内容id

      isRevoke: false, //撤销权限
      isView: false, //查看权限
      isEdit: false, //编辑权限
      permissionButtonList: [], //权限列表
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  created() {
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    // this.tableLoading = true;
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    this.getData();
    this.getCourseList();
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isRevoke = val.indexOf("record:revoke") != -1;
          this.isView = val.indexOf("record:view") != -1;
          this.isEdit = val.indexOf("record:update") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getPermissionButton,
    // 获取课程资源列表
    getCourseList() {
      this.$api.getPublishCourse({}).then((res) => {
        if (res.data.code === 0) {
          res.data.data.map((item) => {
            let courseInfo = {
              id: item.id,
              name: item.courseName
            };
            this.courseOptions.push(courseInfo);
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取列表信息
    getData() {
      let params = { ...this.searchForm };
      params.startTime = this.dataTimes.length > 0 ? this.dataTimes[0] : '';
      params.endTime = this.dataTimes.length > 0 ? this.dataTimes[1] : '';
      params.current = this.page.num;
      params.size = this.page.size;
      this.$api.getPublishPage(params).then((res) => {
        // this.tableLoading = false;
        this.pageLoading.close();
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          if (res.data.data.total > 0) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close();
      });
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
      // this.$router.push({ path: "/sendRecordManage/detail",  })
    },
    // 重置
    resetFun() {
      this.searchForm = {
        catalogueName: '',
        sendPerson: '',
        courseName: '',
        startTime: '',
        endTime: '',
        status: '',
      };
      this.dataTimes = [];
      this.page.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
    },
    // 页码变化
    handleCurrentChange(data) {
      this.page.num = data;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.page.size = data;
      this.page.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getData();
    },
    // 撤销操作
    handleDel(row) {
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;', }, '确定要撤销该推送记录？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px', }, '是否确定撤销该推送记录，撤销后，需要重新编辑推送。'),
        ]), '提示', { confirmButtonText: '确定', cancelButtonText: '取消', iconClass: 'el-icon-question', type: 'warning', customClass: 'warningMessage', }
      ).then(() => {
        this.$api.changePushCourseStatus({ publishId: row.id, status: 0 }).then((res) => {
          if (res.data.code === 0) {
            this.$message({ message: '撤销成功', type: 'success', duration: 2000, onClose: () => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              // this.tableLoading = true;
              this.getData();
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => {});
    },
    // 编辑推送
    handleEdit(row) {
      this.$router.push({ path: '/courseResource/sendEdit', query: { id: row.id, courseId: row.courseId, courseCatalogId: row.courseThemeCatalogueContentId, catalogueName: row.catalogueName, getType: 'record' } });
    },
    // 预览
    handleView(row) {
      this.courseContentCatalogId = row.id;
      this.courseViewDialogVisible = true;
    },
    //查看推送学生记录
    handleShowStudent(row){
      this.publishStudentListDialogVisible = true;
      this.publishId = row.id
    },
    //打开学生列表样式
    handleOpenDialog(){
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getPublishStudentList()
    },
    //获取推送学生记录list
    getPublishStudentList(){
      this.$api.getPublishStudentList({ id: this.publishId, current: this.publishStudentPage.num, size: this.publishStudentPage.size }).then((res) => {
        this.pageLoading.close()
        if (res.data.code === 0) {
          let list = res.data.data.list
          this.publishStudentTableData = list;
          this.publishStudentPage.total = res.data.data.total;
          if (res.data.data.total > 0) {
            this.publishStudentPageFlag = true;
          } else {
            this.publishStudentPageFlag = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    // 页码变化
    handlePublishStudentCurrentChange(data) {
      this.publishStudentPage.num = data;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getPublishStudentList();
    },
    // 每页条数变化
    handlePublishStudentSizeChange(data) {
      this.publishStudentPage.size = data;
      this.publishStudentPage.num = 1;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getPublishStudentList();
    },
    //推动学生列表弹窗关闭
    handlePublishStudentListClose(){
      this.publishStudentListDialogVisible = false;
      this.$nextTick(() => {
        this.publishStudentTableData = []
        this.publishId = null
        this.publishStudentPageFlag = false
        this.publishStudentPage = {
          num: 1,
          size: 10,
          total: 0,
        }
      });
    },
    // 详情
    handleDetail(row) {
      this.$router.push({ path: '/sendRecordManage/detail', query: { id: row.id } });
    },
    // 弹窗关闭
    handleClose() {
      this.sendDialogVisible = false;
      this.$nextTick(() => {
        this.sendDialogForm = {
          organization: '',
          schoolName: '',
          gradeName: '',
          gradeNum: '',
          sort: '',
          status: true,
          description: '',
        };
        this.buttonloading = false;
      });
    },
    // 提交操作
    handleSubmit() {
      this.$refs.sendDialogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            organization: this.sendDialogForm.organization,
            schoolName: this.sendDialogForm.schoolName,
            gradeName: this.sendDialogForm.gradeName,
            gradeNum: this.sendDialogForm.gradeNum,
            sort: this.sendDialogForm.sort,
            status: this.sendDialogForm.status ? '1' : '0',
            description: this.sendDialogForm.description,
          };
          let url = '';
          if (this.handleType == 'add') {
            url = '/api/educourse/save';
          } else {
            params.id = this.courseId;
            url = '/api/educourse';
          }
          // this.$api.courseHandle(url, params, this.handleType).then((res) => {
          //   if (res.data.code === 0) {
          //     this.$message({
          //       message: "保存成功",
          //       type: "success",
          //       duration: 2000,
          //       onClose: () => {
          //         this.buttonloading = false;
          //         if (this.handleType == "add") {
          //           this.courseSettingDialogVisible = true;
          //           this.$nextTick(() => {
          //             this.courseId = res.data.data;
          //             this.courseSettingType = "introduce";
          //           });
          //         } else {
          //           this.pageLoading = true;
          //           this.getData();
          //         }
          //         this.handleClose();
          //       },
          //     });
          //   } else {
          //     this.buttonloading = false;
          //     this.$message.error(res.data.msg);
          //   }
          // });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.send-record-manage {
  width: calc(100% - 40px);
  height: calc(100% - 50px);
  padding: 30px 20px 20px 20px;
  background-color: #ffffff;
  position: relative;
  .send-record-manage-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
  .table-content{
    height: calc(100% - 123px);
    .tableList{
      height: 100%;
    }
  }

  .status-show{
    display: inline-block;
    min-width: 38px;
    min-height: 10px;
    padding: 5px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }
}
/deep/.el-input__count {
  &::before {
    content: "已输入 ";
  }
}
.student-record-list{
  width: 100%;
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  .tableList{
    width: 100%;
    height: 100%;
  }
}
</style>
